@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');





body {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  min-height: 40rem;
  justify-items: center;
  moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
   -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
  
}

.nav {
  display: flex;
  width: 100%;
  background-color: transparent !important;
  background-color: white !important;
}

.bg-light {
  --bs-bg-opacity: 0 !important;
 
}

.topSection {
  display: flex;
  flex-direction: column;
min-height: 35rem;
min-width: 100%;
color: black;
font-family: 'Montserrat', sans-serif;
z-index: 3;
}

.image {
  display:flex ;
  flex-direction: column;
  align-content: center;
  height: 100%
}

h1, h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  color: "black";
  font-weight: 400;
}

h4 {
 font-family: 'Montserrat', sans-serif;
 color: "black";
  font-weight: 400;
  
}


.layerOne {
  z-index: 1;
  min-height: 32rem;
  min-width: 100%;
  background-color: white;
  opacity: 0.88;
  height: auto;
 

}

.outerLayer {
  width: auto;
  min-height: 32rem;
  z-index: 0;
  background-image: url(https://www.halorestorationservices.com/wp-content/uploads/2019/08/pressure-washing-pavestone.jpg);
  background-size: cover;
  overflow-x: hidden;

}

.span {
  font-family: 'Montserrat', sans-serif;

}

.image {
  display: flex;
  align-items: center;
  min-height: 26rem;
  height: auto;
width: auto;
  
}

.headerChild {
  display: flex;
  min-height: 35rem;
  height: auto;
  width: auto;
  max-width: 70%;
  min-width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  
  
}

.me-auto p1:hover  {
  color: rgba(255,241,2,255);
  transition: 0.2s;
  position: absolute;
}

.me-auto navbar-nav {
  display: flex;
 z-index: 1000;
}

.test {
color: black;


}

.test :active {
  transform: translateY(4px)
}

.me-auto {
 position: fixed;
 width: 97%;
 margin: 0;

}

p2, p3, p4 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}

p2 {
  font-size: 22px;
}
.pics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  min-height: 34rem;
  width: 100%;
  gap: 5rem;
  background-color: #f9f9f9;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pics div {
  moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
   -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
}

.titleOne, .titleTwo, .titleThree, .titleFour, .titleFive, .titleSix {
display: flex;
  width: 100%;
height: 40%;
color: #FFF200;
background-color: rgba(2, 2, 2, 0.4);
justify-content: center;
align-items: center;
border-top: 1px solid #FFF200;

} 

.gOne, .gTwo, .gThree, .gFour, .gFive, .gSix, .gSeven, .gEight {
  color: #FFF200;
  display: flex;
  width: 100%;
  height: 20%;
  background-color: rgba(2, 2, 2, 0.4);
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #FFF200;
}


.reviews, .wrap {
  display: flex;
  height: auto;
  min-height: 34rem;
  width: 100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 10px;
 
  margin-bottom: 5rem;

}

.reviews {
  margin-top: 5rem;
}

.first, .second, .third, .fourth {
display: flex;
  flex-direction: column;
  height: auto;
  min-height: 8rem;
  max-height: 30rem;
  overflow-y: auto;
  border-radius: 10px;
  width: auto;
  max-width: 64%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

}

.first, .third {
  margin-right: 1rem;
}

.second, .fourth {
  margin-left: 1rem;
}

Paper {
  display: flex;
  flex-direction: row;

}

.contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  min-height: 38rem;
  width: 100%;
  gap: 5rem;
  
}


.contactWrap, .thankYou {
  display: flex;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  min-height: 38rem;
  gap: 8rem;
  margin-bottom: 1rem;
  margin-top: 4rem;
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
   -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
}

.contactForm {
  display: flex;
  flex-direction: column;
  color: black;
  width: 34rem;
  height: 34rem;
  border: 0px;
  border-radius: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);

-moz-animation: fadein 1s; /* Firefox < 16 */
-ms-animation: fadein 1s; /* Internet Explorer */
 -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

.ctIcons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toast {
  
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
   -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.footer {
  display: flex; 
  align-items: center;
  align-content: center;
  width: 100%;
  height: auto;
  min-height: 5rem;
  max-height: 10rem;
  flex-direction: column;
}

body.modal-open {
  padding: 0 !important;
  padding-right: 0 !important; 
  overflow: auto !important;
  margin: 0;
}

.gallery {
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  min-height: 34rem;
  width: 100%;
  align-items: center;
  background-color: #f9f9f9;
  padding-left: .5rem;
  padding-right: .5rem;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
  border-color: black !important;
 
}


.swiper-pagination-bullet-active { 
  background: #FFF200 !important;
} 



button :hover {
  color: #FFF200;
}



a :hover {
  color: #FFF200;
}



.image Button :hover{
  color: #FFF200;
}
